import axios from "axios"
import { showToast } from 'vant'
//创建axios实例
let request = axios.create({
    baseURL: 'https://wyxc.047x.com',
    timeout: 50000
})
//请求拦截器
request.interceptors.request.use(config => {
    config.headers.token = localStorage.getItem('TOKEN')
    return config;
});
//响应拦截器
request.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    //处理网络错误
    let msg = '';
    console.log(error);
    let status = error.response.status;
    switch (status) {
        case 401:
            msg = "token过期";
            localStorage.removeItem('TOKEN')
            window.location.reload()
            break;
        case 403:
            msg = '无权访问';
            break;
        case 404:
            msg = "请求地址错误";
            break;
        case 500:
            msg = "服务器出现问题";
            break;
        default:
            msg = "无网络";
    }
    showToast(msg)
    return Promise.reject(error)
});
export default request;